import * as React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/layout'

const ReglementInterieur = () => {
    return (
        <>
            <Helmet>
                <title>Educa'dog : Centre d'éducation positive à Léglise - Notre règlement d'ordre intérieur</title>
                <descrtiption>Educa'dog est un centre d'éducation canine positive situé chemin du Stria à Léglise, en province de Luxembourg. Nous n'utilisons que les méthodes positive et respectueuses du chien.</descrtiption>
            </Helmet>
            <Layout>
                <section id="reglement-interieur">
                    <h1 className="page-title fredoka">
                        Règlement d’ordre intérieur 2021 du centre d’éducation positive Educa’dog de Léglise
                    </h1>
                    <div className="grid">
                        <section className="block">
                            <h2 className="block-title balsamiq">
                                Votre chien
                            </h2>
                            <ul>
                                <li>
                                    Il sera tenu en laisse dans l’enceinte du terrain, aux leçons ou autres activités sauf dérogation donnée par les moniteurs ou pour certains exercices.
                                </li>
                                <li>
                                    Le carnet de vaccination (passeport européen) sera présenté à l’inscription ainsi qu’à chaque renouvellement de la cotisation annuelle. Le vaccin de la toux de chenil est obligatoire ainsi que le vaccin contre la rage si votre chien provient de l’étranger.
                                </li>
                                <li>
                                    Si votre chienne est en chaleur, celle-ci ne sera pas acceptée au cours durant cette période.
                                </li>
                                <li>
                                    Les moniteurs pourront demander que votre chien soit muselé si celui-ci présente des comportements à risque pour les autres participants (chien ou humain).
                                </li>
                                <li>
                                    Tout chien catégorisé (catégorie 1) devra être tenu en laisse et muselé sur le terrain et aux alentours. Celui-ci ne pourra être démuselé que sur demande expresse des moniteurs. Sources : <a href="https://www.police.be/5301/actualites/enregistrement-et-detention-dun-chien-de-categorie-1" target="_blank">https://www.police.be/5301/actualites/enregistrement-et-detention-dun-chien-de-categorie-1</a>
                                </li>
                            </ul>
                        </section>
                        <section className="block black">
                            <h2 className="block-title balsamiq">
                                Vous
                            </h2>
                            <ul>
                                <li>
                                    Pendant et en dehors des cours, vous êtes responsable de votre chien et de tout dégât éventuel causé par celui-ci, que le dégât soit de nature corporelle ou matérielle.<br />
                                    La souscription à une assurance R.C. familiale est donc obligatoire. La preuve de cette affiliation est à fournir lors de l’inscription. Sans assurance en ordre, l’inscription aux activités du centre Educa’dog est refusée.
                                </li>
                                <li>
                                    L’âge minimum requis pour participer aux cours est de 14 ans. L’exigence principale étant d’être apte à tenir son chien, à comprendre et à exécuter les différents exercices. Certaines exceptions pourront être débattues par les moniteurs et sous réserve d’une autorisation parentale.<br />
                                    Pour les mineurs, un parent responsable doit rester sur le site du centre Educa’dog durant toute l’heure de cours.
                                </li>
                                <li>
                                    Les accompagnants, familles ou amis, doivent rester à l’extérieur du terrain où se donne la leçon pour leur sécurité et afin de ne pas distraire le chien.
                                </li>
                                <li>
                                    Nous insistons sur la ponctualité. Nous vous recommandons d’arriver 10 minutes avant le cours afin de permettre à votre chien de se détendre et de faire ses besoins. Les déjections sur le terrain ou dans l’enceinte du centre doivent être ramassées. Veillez à prévoir vos sacs à déjections, une poubelle est à votre disposition à l’entrée du terrain.
                                </li>
                                <li>
                                    Toute personne doit respecter les directives des moniteurs et ne pourra entrer ou sortir du terrain sans un accord préalable de ceux-ci.
                                </li>
                                <li>
                                    Vous ne pourrez travailler qu’un seul chien à la fois par heure de cours
                                </li>
                                <li>
                                    Les agrès sont réservés aux cours. Il est interdit de les utiliser en l’absence d’un moniteur.
                                </li>
                                <li>
                                    Il est interdit de fumer ou de vapoter pendant les cours.
                                </li>
                                <li>
                                    Tout acte de maltraitance est formellement interdit et sera passible d’une exclusion définitive et sans appel du centre Educa’dog
                                </li>
                                <li>
                                    Vous acceptez et appliquez la pédagogie initiée et attendue par les moniteurs du centre Educa’dog.
                                </li>
                                <li>
                                    Une fois l’inscription à un cours effectuée, le cours est dû et ne peut être remboursé en cas d’annulation.
                                </li>
                            </ul>
                        </section>
                        <section className="block">
                            <h2 className="block-title balsamiq">
                                Matériel nécessaire
                            </h2>
                            <ul>
                                <li>
                                    Une laisse courte(+/- 1.20m)
                                </li>
                                <li>
                                    Un collier plat ou un harnais adapté au chien
                                </li>
                                <li>
                                    Des friandises/récompenses
                                </li>
                                <li>
                                    Un jouet
                                </li>
                                <li>
                                    Des sachets pour les déjections
                                </li>
                                <li>
                                    Les colliers à pointes, étrangleurs, électriques sont INTERDITS, même à l’envers
                                </li>
                            </ul>
                        </section>
                        <section className="block black">
                            <h2 className="block-title balsamiq">
                                Généralités
                            </h2>
                            <ul>
                                <li>
                                    Nous vous demandons de garer votre véhicule sur le parking à l’entrée du chemin du Stria, afin de ne pas gêner les utilisateurs de ce dernier.
                                </li>
                                <li>
                                    Chaque membre est invité à conserver sa bonne humeur et sa courtoisie en toute circonstance.
                                </li>
                                <li>
                                    Chacun est là pour apprendre et s’améliorer, tant dans l’intérêt du chien que pour son plaisir personnel. Le respect de chacun sera toujours garant d’une bonne ambiance au sein du centre Educa’dog.
                                </li>
                                <li>
                                    Les résultats obtenus seront à la hauteur de votre patience, de votre volonté et de la confiance que vous accorderez à votre chien. Nous vous recommandons de travailler avec votre chien, sur base de ce que vous aurez appris au cours, plusieurs fois par semaine et pendant des courtes périodes.
                                </li>
                                <li>
                                    Ne rendez pas la vie difficile aux moniteurs. Ils partagent leur savoir-faire pour vous aider à progresser avec votre chien. Toute agression verbale ou physique envers un membre du centre Educa’dog sera immédiatement sanctionnée. Le comité se réserve le droit d’exclure un membre après délibération du Conseil d’Administration. Cette décision d’exclusion éventuelle sera sans appel.
                                </li>
                                <li>
                                    Les photos et/ou vidéos prises pendant les activités du centre Educa’dog sont considérées libres de droit à l’image pour les outils de communication du centre Educa’dog : site internet, groupe et page Facebook, presse locale, … L’utilisation de ces images n’a d’autre but que d’illustrer les projets et activités du centre Educa’dog. Les personnes désirant s’opposer à la diffusion de leur droit à l’image doivent le mentionner par écrit sur leur fiche d’inscription.
                                </li>
                            </ul>
                        </section>
                    </div>
                </section>
            </Layout>
        </>
    )
}

export default ReglementInterieur